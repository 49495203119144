const ROUTES = {
  "/": {
    "name": "HomePage2a205d4261834b8788f3953177c556a9",
    "type": "HTML",
    "key": "home-page"
  },
  "/_admin/*": {
    "name": "AdminPagesFe0cca34D73043e69a49F5c58547ee73",
    "type": "HTML",
    "key": "admin-pages"
  },
  "/about": {
    "name": "AboutUsPage0093bda078ec4eeeAe16C3367ec9c2f4",
    "type": "HTML",
    "key": "about-us-page"
  },
  "/about/leveling": {
    "name": "AboutLevelingPageF915689f1c9b4563A299A7a10facfd61",
    "type": "HTML",
    "key": "about-leveling-page"
  },
  "/contact": {
    "name": "Contact529f3631872d4918859a4779ec72c41e",
    "type": "HTML",
    "key": "contact"
  },
  "/events/public": {
    "name": "PublicEventsPage7f10eec6566444e8826b7903f3f1dce0",
    "type": "HTML",
    "key": "public-events-page"
  },
  "/join": {
    "name": "JoinUsPageFb45be27735541b9B30420b671017d44",
    "type": "REFERENCE",
    "key": "join-us-page"
  },
  "/members/*": {
    "name": "MemberPagesE3f1e402B1bd497197028f74ce04e739",
    "type": "HTML",
    "key": "member-pages"
  },
  "/members/messages/*": {
    "name": "MessagePages51112a3366c74e54A1b805e6035cb2f0",
    "type": "HTML",
    "key": "message-pages"
  },
  "/resources": {
    "name": "ResourcesPage159968fcB64d4013A4b4489433dd62ac",
    "type": "REFERENCE",
    "key": "resources-page"
  },
  "/signup/*": {
    "name": "SignupPagesA3ed34c78c5b40ddA632A9174850480f",
    "type": "REFERENCE",
    "key": "signup-pages"
  }
};

export default ROUTES;