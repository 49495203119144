import AppStore from "./AppStore";
import { buildClassName } from "./util";

// id: "45a335cd-27de-4aec-a60a-42c9c9c91ecc"
// title: "Short Header"
// type: :html
// key: "short-header"
// parent_id: nil
export function ShortHeader45a335cd27de4aecA60a42c9c9c91ecc(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} ShortHeader45a335cd27de4aecA60a42c9c9c91ecc site-header`}>
      <div className="background-image" />
      <div className="header-top">
        {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogo0a3b38935a3a4860B77eDa853090e81f", parentTag)} label={<DefaultLogo5543b37cBca44f4a8a41A526e9a50aae parentTag="Label89b7885961d2441bA1d35462e5f54b75" alt="logo" />} {...props} />}
        {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("Navigation25b0693dBa7f4c3d88cc474e9fa94db1", parentTag)} navMenuSlug="members-primary-nav" {...props} />}
      </div>
    </div>
  );
}

// id: "a705f2a6-e02c-4dff-aea2-e122c01f2d86"
// title: "New Component"
// type: :html
// key: "new-component"
// parent_id: nil
export function NewComponentA705f2a6E02c4dffAea2E122c01f2d86(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <>

    </>
  );
}

// id: "b5445d48-9560-4185-8f87-931763f5956f"
// title: "Label"
// type: :reference
// key: "label"
// parent_id: nil
export function LabelB5445d48956041858f87931763f5956f(props: any) {
  return (
    <DefaultLogo5543b37cBca44f4a8a41A526e9a50aae parentTag="LabelB5445d48956041858f87931763f5956f" {...props} />
  );
}

// id: "58ecdcda-f308-4bac-8433-df086fa4c3d1"
// title: "Members Area Header"
// type: :reference
// key: "members-area-header"
// parent_id: nil
export function MembersAreaHeader58ecdcdaF3084bac8433Df086fa4c3d1(props: any) {
  return (
    <ShortHeader45a335cd27de4aecA60a42c9c9c91ecc parentTag="MembersAreaHeader58ecdcdaF3084bac8433Df086fa4c3d1" header-top={<DefaultHeaderTop1b83143f537845b788f6Eb76e8910071 parentTag="HeaderTop051fc88fAbf541938f3cB6151fc2ac7f" navMenuSlug="members-primary-nav" />} {...props} />
  );
}

// id: "e3f1e402-b1bd-4971-9702-8f74ce04e739"
// title: "Member Pages"
// type: :html
// key: "member-pages"
// parent_id: nil
export function MemberPagesE3f1e402B1bd497197028f74ce04e739(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MemberPagesE3f1e402B1bd497197028f74ce04e739 gf-page-layout`}>
      {props["header"] || <MembersAreaHeader58ecdcdaF3084bac8433Df086fa4c3d1 parentTag="Header2524b34a42004b09B7b8Fce53567f785" {...props} />}
      <main className="main">
        {props["secondary-nav"] || <SecondaryNavigation parentTag={buildClassName("SecondaryNav0bfcb43aD1c84bd18382A60ffa563106", parentTag)} {...props} />}
        {props.children}
      </main>
      {props["footer"] || <DefaultFooterF1e1f49d52f64033B1fa531bf89ecb79 parentTag="FooterCeb57a4e1ed5475a8af4F3515d392e42" {...props} />}
    </div>
  );
}

// id: "01ac3580-c900-4656-885c-a2212a9f6cfe"
// title: ""
// type: :reference
// key: "header"
// parent_id: "2a205d42-6183-4b87-88f3-953177c556a9"
export function Header01ac3580C9004656885cA2212a9f6cfe(props: any) {
  return (
    <HomeHeaderD937be85Ee1e42d2Ac2b609d1ce6a8b3 parentTag="Header01ac3580C9004656885cA2212a9f6cfe" {...props} />
  );
}

// id: "81470ecd-39d9-47b6-81f5-76171b25fbad"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "08c9edf5-dbd7-433e-9660-2164fd6e7c93"
export function Footer81470ecd39d947b681f576171b25fbad(props: any) {
  return (
    <DefaultFooterF1e1f49d52f64033B1fa531bf89ecb79 parentTag="Footer81470ecd39d947b681f576171b25fbad" {...props} />
  );
}

// id: "869ce3ef-675b-41ed-bfb6-498ab277380f"
// title: ""
// type: :reference
// key: "header"
// parent_id: "fb45be27-7355-41b9-b304-20b671017d44"
export function Header869ce3ef675b41edBfb6498ab277380f(props: any) {
  return (
    <SecondaryMarketingHeader2cdf2519Aa4049d1B25bAa31366de347 parentTag="Header869ce3ef675b41edBfb6498ab277380f" title="Join Us" {...props} />
  );
}

// id: "66216c21-a7a5-4a21-b150-472767e61e7b"
// title: ""
// type: :text
// key: "title"
// parent_id: "7d1a8db0-9bbf-4aec-88b6-38ec1ab58e05"
export const Title66216c21A7a54a21B150472767e61e7b = "Leveling";

// id: "5fd60a04-4db0-4e60-9075-fc9cca33f184"
// title: "Members Header"
// type: :reference
// key: "header"
// parent_id: nil
export function Header5fd60a044db04e609075Fc9cca33f184(props: any) {
  return (
    <MembersAreaHeader58ecdcdaF3084bac8433Df086fa4c3d1 parentTag="Header5fd60a044db04e609075Fc9cca33f184" {...props} />
  );
}

// id: "f25e9903-ad18-4057-90e6-33267f630b84"
// title: "Page Quote"
// type: :html
// key: "page-quote"
// parent_id: nil
export function PageQuoteF25e9903Ad18405790e633267f630b84(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PageQuoteF25e9903Ad18405790e633267f630b84`}>
      <div>
      </div>
      <div>
      </div>
    </div>
  );
}

// id: "7d1a8db0-9bbf-4aec-88b6-38ec1ab58e05"
// title: ""
// type: :reference
// key: "header"
// parent_id: "f915689f-1c9b-4563-a299-a7a10facfd61"
export function Header7d1a8db09bbf4aec88b638ec1ab58e05(props: any) {
  return (
    <SecondaryMarketingHeader2cdf2519Aa4049d1B25bAa31366de347 parentTag="Header7d1a8db09bbf4aec88b638ec1ab58e05" title="Leveling" {...props} />
  );
}

// id: "611459b1-759f-4b57-8134-78467c6590f0"
// title: ""
// type: :reference
// key: "header"
// parent_id: "51112a33-66c7-4e54-a1b8-05e6035cb2f0"
export function Header611459b1759f4b57813478467c6590f0(props: any) {
  return (
    <MembersAreaHeader58ecdcdaF3084bac8433Df086fa4c3d1 parentTag="Header611459b1759f4b57813478467c6590f0" {...props} />
  );
}

// id: "216025b7-ce9f-4943-9536-0a76e5532d6e"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "86c92b6e-d89a-4781-b0c3-86a4d5e7a9b7"
export const ContentSlug216025b7Ce9f494395360a76e5532d6e = "home-page-quote";

// id: "2354d25a-d765-45de-ada5-00824b114b9d"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "bcd7ffef-1f49-4eda-80ff-eb861e808c24"
export const PostSlug2354d25aD76545deAda500824b114b9d = "home-page-posts2";

// id: "2a205d42-6183-4b87-88f3-953177c556a9"
// title: "Home Page"
// type: :html
// key: "home-page"
// parent_id: nil
export function HomePage2a205d4261834b8788f3953177c556a9(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const PageQuote = AppStore.presets["PageQuote"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePage2a205d4261834b8788f3953177c556a9 page gf-page-layout page gf-page-layout`}>
      {props["header"] || <HomeHeaderD937be85Ee1e42d2Ac2b609d1ce6a8b3 parentTag="Header01ac3580C9004656885cA2212a9f6cfe" {...props} />}
      {props["welcome-back"] || <WelcomeBackPopUp parentTag={buildClassName("WelcomeBack3920dc0b4f0848128dc1Fa4b5a3ed290", parentTag)} {...props} />}
      {props["body"] || <MarketingBody7b6b8e44De504ab5B490E9584bb20312 parentTag="BodyDf7db2728ced44ad95c429fa17906182" {...props} />}
      {props["quote"] || <PageQuote parentTag={buildClassName("Quote86c92b6eD89a4781B0c386a4d5e7a9b7", parentTag)} contentSlug="home-page-quote" {...props} />}
      {props["footer"] || <DefaultFooterF1e1f49d52f64033B1fa531bf89ecb79 parentTag="Footer9637e9a4Ea49448b93063dd7602e3eae" {...props} />}
    </div>
  );
}

// id: "05147f77-ebdb-4ab8-a06e-4efe7dc8ab26"
// title: ""
// type: :html
// key: "social-links"
// parent_id: "f1e1f49d-52f6-4033-b1fa-531bf89ecb79"
export function SocialLinks05147f77Ebdb4ab8A06e4efe7dc8ab26(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SocialLinks05147f77Ebdb4ab8A06e4efe7dc8ab26 flex flex-row justify-center gap-6 flex flex-row justify-center gap-6`}>
      <a className="hover:brightness-110" href="https://www.facebook.com/profile.php?id=61550585936211">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Facebook icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M32 0C14.356 0 0 14.356 0 32C0 49.644 14.356 64 32 64C49.644 64 64 49.644 64 32C64 14.356 49.644 0 32 0ZM32 4.92308C46.9833 4.92308 59.0769 17.0167 59.0769 32C59.0769 45.6189 49.0711 56.8173 36 58.75V39.8269H43.6442L44.8462 32.0673H36V27.8173C36 24.5927 37.0495 21.7308 40.0673 21.7308H44.9135V14.9519C44.0618 14.8387 42.2625 14.5865 38.8558 14.5865C31.7468 14.5865 27.5769 18.3428 27.5769 26.8942V32.0577H20.2692V39.8269H27.5865V58.6827C14.719 56.5766 4.92308 45.4748 4.92308 32C4.92308 17.0167 17.0167 4.92308 32 4.92308Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://www.instagram.com/mystiqueenmt/">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Instagram icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M18.5769 0C8.34616 0 0 8.34616 0 18.5769V45.4423C0 55.6538 8.34616 64 18.5769 64H45.4423C55.6538 64 64 55.6539 64 45.4231V18.5769C64 8.34616 55.6539 0 45.4231 0H18.5769ZM18.5769 4.92308H45.4231C53 4.92308 59.0769 11 59.0769 18.5769V45.4231C59.0769 53 53 59.0769 45.4423 59.0769H18.5769C11 59.0769 4.92308 53 4.92308 45.4423V18.5769C4.92308 11 11 4.92308 18.5769 4.92308ZM51.6923 9.84615C50.3269 9.84615 49.2308 10.9423 49.2308 12.3077C49.2308 13.6731 50.3269 14.7692 51.6923 14.7692C53.0577 14.7692 54.1538 13.6731 54.1538 12.3077C54.1538 10.9423 53.0577 9.84615 51.6923 9.84615ZM32 14.7692C22.5192 14.7692 14.7692 22.5192 14.7692 32C14.7692 41.4808 22.5192 49.2308 32 49.2308C41.4808 49.2308 49.2308 41.4808 49.2308 32C49.2308 22.5192 41.4808 14.7692 32 14.7692ZM32 19.6923C38.8269 19.6923 44.3077 25.1731 44.3077 32C44.3077 38.8269 38.8269 44.3077 32 44.3077C25.1731 44.3077 19.6923 38.8269 19.6923 32C19.6923 25.1731 25.1731 19.6923 32 19.6923Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://twitter.com/mystiqueenmt">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Twitter icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M8 0C3.61317 0 0 3.61317 0 8V56C0 60.3868 3.61317 64 8 64H56C60.3868 64 64 60.3868 64 56V8C64 3.61317 60.3868 0 56 0H8ZM8 5.33333H56C57.5012 5.33333 58.6667 6.49883 58.6667 8V56C58.6667 57.5012 57.5012 58.6667 56 58.6667H8C6.49883 58.6667 5.33333 57.5012 5.33333 56V8C5.33333 6.49883 6.49883 5.33333 8 5.33333ZM41.25 16C36.754 16 33.0988 19.6552 33.1042 24.1458C33.1042 24.9832 33.3958 25.5442 33.3958 26.1042C26.6545 25.8215 21.0557 22.4608 17.125 17.6875C16.2823 18.8128 16 20.2119 16 21.6146C16 24.4199 17.0931 26.6674 19.3438 28.6354C17.8504 28.4328 17.1253 28.0704 16 27.5104C16 31.4411 18.5029 34.527 22.1563 35.375C22.1563 35.375 20.7489 35.6563 19.9063 35.6563C19.2663 35.6563 18.5 35.375 18.5 35.375C19.6253 38.463 22.43 40.9896 26.0833 40.9896C23.278 42.9576 19.6221 44.3542 15.9688 44.3542H14C16.6667 46.6688 20.6635 48 26.3542 48C41.2395 48 49.3958 35.6563 49.3958 24.9896V23.8646C50.8038 22.7393 52.2027 21.3365 53.3333 19.6458C51.648 20.4938 50.2428 20.7694 48.5521 21.0521C50.2374 19.9321 51.6483 18.5252 52.2083 16.5625C50.8057 17.3998 49.1243 18.2382 47.1563 18.5208C45.7483 16.8408 43.5007 16 41.25 16Z" />
        </svg>
      </a>
    </div>
  );
}

// id: "9637e9a4-ea49-448b-9306-3dd7602e3eae"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "2a205d42-6183-4b87-88f3-953177c556a9"
export function Footer9637e9a4Ea49448b93063dd7602e3eae(props: any) {
  return (
    <DefaultFooterF1e1f49d52f64033B1fa531bf89ecb79 parentTag="Footer9637e9a4Ea49448b93063dd7602e3eae" {...props} />
  );
}

// id: "2524b34a-4200-4b09-b7b8-fce53567f785"
// title: ""
// type: :reference
// key: "header"
// parent_id: "e3f1e402-b1bd-4971-9702-8f74ce04e739"
export function Header2524b34a42004b09B7b8Fce53567f785(props: any) {
  return (
    <MembersAreaHeader58ecdcdaF3084bac8433Df086fa4c3d1 parentTag="Header2524b34a42004b09B7b8Fce53567f785" {...props} />
  );
}

// id: "ebb9cbbe-db8c-41ef-b246-e0412bcf458d"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "051fc88f-abf5-4193-8f3c-b6151fc2ac7f"
export const NavMenuSlugEbb9cbbeDb8c41efB246E0412bcf458d = "members-primary-nav";

// id: "44899e44-edae-4c19-941e-2d488133be54"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "d937be85-ee1e-42d2-ac2b-609d1ce6a8b3"
export function HeaderTop44899e44Edae4c19941e2d488133be54(props: any) {
  return (
    <DefaultHeaderTop1b83143f537845b788f6Eb76e8910071 parentTag="HeaderTop44899e44Edae4c19941e2d488133be54" {...props} />
  );
}

// id: "8546a41d-4bee-4418-b829-f988db69fdea"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "0093bda0-78ec-4eee-ae16-c3367ec9c2f4"
export function Footer8546a41d4bee4418B829F988db69fdea(props: any) {
  return (
    <DefaultFooterF1e1f49d52f64033B1fa531bf89ecb79 parentTag="Footer8546a41d4bee4418B829F988db69fdea" {...props} />
  );
}

// id: "461bf7db-681f-42a2-8102-5116861747f2"
// title: ""
// type: :text
// key: "title"
// parent_id: "2a52d892-67fb-4ba6-a419-68348b546488"
export const Title461bf7db681f42a281025116861747f2 = "Secondary Page";

// id: "df7db272-8ced-44ad-95c4-29fa17906182"
// title: ""
// type: :reference
// key: "body"
// parent_id: "2a205d42-6183-4b87-88f3-953177c556a9"
export function BodyDf7db2728ced44ad95c429fa17906182(props: any) {
  return (
    <MarketingBody7b6b8e44De504ab5B490E9584bb20312 parentTag="BodyDf7db2728ced44ad95c429fa17906182" {...props} />
  );
}

// id: "1346fb97-9fa2-42eb-a4bc-38be0ffdcfbe"
// title: "Marketing Primary Navigation"
// type: :text
// key: "navMenuSlug"
// parent_id: "9330c18d-9388-4ef2-9b76-72475145834a"
export const NavMenuSlug1346fb979fa242ebA4bc38be0ffdcfbe = "marketing-primary-nav";

// id: "0387fc50-3ecc-4317-bbc8-5144ecf0434b"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "25b0693d-ba7f-4c3d-88cc-474e9fa94db1"
export const NavMenuSlug0387fc503ecc4317Bbc85144ecf0434b = "members-primary-nav";

// id: "91ebe7e4-b088-41d8-90f2-000e0c69ff6d"
// title: ""
// type: :reference
// key: "header"
// parent_id: "529f3631-872d-4918-859a-4779ec72c41e"
export function Header91ebe7e4B08841d890f2000e0c69ff6d(props: any) {
  return (
    <SecondaryMarketingHeader2cdf2519Aa4049d1B25bAa31366de347 parentTag="Header91ebe7e4B08841d890f2000e0c69ff6d" {...props} />
  );
}

// id: "10afbef2-7f62-41f5-9dde-36905cfd3fc9"
// title: ""
// type: :text
// key: "alt"
// parent_id: "5543b37c-bca4-4f4a-8a41-a526e9a50aae"
export const Alt10afbef27f6241f59dde36905cfd3fc9 = "logo";

// id: "32d1125b-5bfc-4f3d-8f16-eb3d4694a049"
// title: ""
// type: :reference
// key: "label"
// parent_id: "049332d0-e30d-43de-9d1b-725f3e17c639"
export function Label32d1125b5bfc4f3d8f16Eb3d4694a049(props: any) {
  return (
    <DefaultLogo5543b37cBca44f4a8a41A526e9a50aae parentTag="Label32d1125b5bfc4f3d8f16Eb3d4694a049" {...props} />
  );
}

// id: "0bfcb43a-d1c8-4bd1-8382-a60ffa563106"
// title: ""
// type: :gf_preset
// key: "secondary-nav"
// parent_id: "e3f1e402-b1bd-4971-9702-8f74ce04e739"
export function SecondaryNav0bfcb43aD1c84bd18382A60ffa563106(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <SecondaryNavigation parentTag={buildClassName("SecondaryNav0bfcb43aD1c84bd18382A60ffa563106", parentTag)} {...props} />
  );
}

// id: "434499a3-34a5-4489-a970-28974e238c53"
// title: ""
// type: :text
// key: "imageUrl"
// parent_id: "5543b37c-bca4-4f4a-8a41-a526e9a50aae"
export const ImageUrl434499a334a54489A97028974e238c53 = "https://mp1md-pub.s3.amazonaws.com/orgs/mystique/logo-wide.png";

// id: "5b8e24f1-295a-4b46-bd31-f72994de0543"
// title: ""
// type: :reference
// key: "header"
// parent_id: "0093bda0-78ec-4eee-ae16-c3367ec9c2f4"
export function Header5b8e24f1295a4b46Bd31F72994de0543(props: any) {
  return (
    <SecondaryMarketingHeader2cdf2519Aa4049d1B25bAa31366de347 parentTag="Header5b8e24f1295a4b46Bd31F72994de0543" title="About Us" {...props} />
  );
}

// id: "05ce8f03-791e-4a61-a479-29c86d1c16ff"
// title: ""
// type: :text
// key: "class"
// parent_id: "049332d0-e30d-43de-9d1b-725f3e17c639"
export const Class05ce8f03791e4a61A47929c86d1c16ff = "linked-logo";

// id: "e1096842-44dd-405c-be2b-f2a095ff746d"
// title: ""
// type: :text
// key: "title"
// parent_id: "2db9e95d-74f1-4d15-9315-2d2836c21938"
export const TitleE109684244dd405cBe2bF2a095ff746d = "Public Events";

// id: "4f303bda-ffc6-431d-8d2a-bed18b399ff5"
// title: ""
// type: :text
// key: "class"
// parent_id: "a2c1e32d-68f9-4167-b0db-0d2319d790ce"
export const Class4f303bdaFfc6431d8d2aBed18b399ff5 = "navigation";

// id: "2867bbf3-340f-4cc9-b61e-eb95cfcc47f4"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "cf6e2702-4b4f-4f4c-ae17-70962679e699"
export const PostSlug2867bbf3340f4cc9B61eEb95cfcc47f4 = "home-hero";

// id: "89dcaf37-9713-4bd0-bb88-db4c56889e93"
// title: ""
// type: :text
// key: "title"
// parent_id: "98a2a473-b6ec-472d-92c0-7c8bacaaa3db"
export const Title89dcaf3797134bd0Bb88Db4c56889e93 = "Secondary Marketing Title 2";

// id: "b6941852-9424-4eb0-bbd0-7b38b3a91637"
// title: "Home Page Hero"
// type: :html
// key: "home-page-hero"
// parent_id: nil
export function HomePageHeroB694185294244eb0Bbd07b38b3a91637(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePageHeroB694185294244eb0Bbd07b38b3a91637 hero`}>
      <div className="title">
        Join a vibrant community.
        <br />
        Elevate your nightlife escapades to new heights!
      </div>
      <div className="buttons">
        <a href="/join" className="Button">
          Join Us
        </a>
        <a href="/events/public" className="Button">
          Events
        </a>
      </div>
    </div>
  );
}

// id: "f915689f-1c9b-4563-a299-a7a10facfd61"
// title: "Levels 1-4 Explained"
// type: :html
// key: "about-leveling-page"
// parent_id: nil
export function AboutLevelingPageF915689f1c9b4563A299A7a10facfd61(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutLevelingPageF915689f1c9b4563A299A7a10facfd61`}>
      {props["header"] || <SecondaryMarketingHeader2cdf2519Aa4049d1B25bAa31366de347 parentTag="Header7d1a8db09bbf4aec88b638ec1ab58e05" title="Leveling" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooterF1e1f49d52f64033B1fa531bf89ecb79 parentTag="Footer0e9267d9F12847e490d4B07c487ddce1" {...props} />}
    </div>
  );
}

// id: "4951fee5-5425-4a88-b782-959b77911b11"
// title: ""
// type: :text
// key: "href"
// parent_id: "5ea3b780-7bc9-4379-bebc-db11566e48d4"
export const Href4951fee554254a88B782959b77911b11 = "/join";

// id: "fb45be27-7355-41b9-b304-20b671017d44"
// title: "Join Us Page"
// type: :reference
// key: "join-us-page"
// parent_id: nil
export function JoinUsPageFb45be27735541b9B30420b671017d44(props: any) {
  return (
    <SecondaryPageB05f02a0748848138cceC46121cd1d77 parentTag="JoinUsPageFb45be27735541b9B30420b671017d44" header={<SecondaryMarketingHeader2cdf2519Aa4049d1B25bAa31366de347 parentTag="Header869ce3ef675b41edBfb6498ab277380f" title="Join Us" />} {...props} />
  );
}

// id: "7c4c8dbf-4187-4d23-b434-6cb200b8f0c8"
// title: "Title - Leveling"
// type: :text
// key: "title"
// parent_id: nil
export const Title7c4c8dbf41874d23B4346cb200b8f0c8 = "Leveling";

// id: "fe0cca34-d730-43e6-9a49-f5c58547ee73"
// title: "Admin Pages"
// type: :html
// key: "admin-pages"
// parent_id: nil
export function AdminPagesFe0cca34D73043e69a49F5c58547ee73(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AdminPagesFe0cca34D73043e69a49F5c58547ee73 gf-page-layout`}>
      {props.children}
    </div>
  );
}

// id: "0093bda0-78ec-4eee-ae16-c3367ec9c2f4"
// title: "About Us"
// type: :html
// key: "about-us-page"
// parent_id: nil
export function AboutUsPage0093bda078ec4eeeAe16C3367ec9c2f4(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutUsPage0093bda078ec4eeeAe16C3367ec9c2f4 page gf-page-layout page gf-page-layout`}>
      {props["header"] || <SecondaryMarketingHeader2cdf2519Aa4049d1B25bAa31366de347 parentTag="Header5b8e24f1295a4b46Bd31F72994de0543" title="About Us" {...props} />}
      {props["body"] || <Body246c81584f044c2391adC65b12d3cb88 />}
      {props["footer"] || <DefaultFooterF1e1f49d52f64033B1fa531bf89ecb79 parentTag="Footer8546a41d4bee4418B829F988db69fdea" {...props} />}
    </div>
  );
}

// id: "629ad493-e75a-4b20-ba0a-5aa7e07ad3cc"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "d937be85-ee1e-42d2-ac2b-609d1ce6a8b3"
export const BackgroundImage629ad493E75a4b20Ba0a5aa7e07ad3cc = "https://mp1md-pub.s3.amazonaws.com/spp/images/page-background-photo.jpg";

// id: "5a6ef2ef-de20-4322-9d4d-01261d604e8d"
// title: ""
// type: :text
// key: "title"
// parent_id: "dc8f580c-8ed1-4478-a276-939deaa7531b"
export const Title5a6ef2efDe2043229d4d01261d604e8d = "Default Title";

// id: "2cdf2519-aa40-49d1-b25b-aa31366de347"
// title: "Secondary Marketing Header"
// type: :html
// key: "secondary-marketing-header"
// parent_id: nil
export function SecondaryMarketingHeader2cdf2519Aa4049d1B25bAa31366de347(props: any) {
  const { parentTag, ...rest } = props;
  props = { title: "Contact", ...rest };
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHeader2cdf2519Aa4049d1B25bAa31366de347 site-header`}>
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTop1b83143f537845b788f6Eb76e8910071 parentTag="HeaderTop5c325ff5Df2245b2Bd745a0742d404c5" {...props} />}
      </div>
      {props["hero"] || <SecondaryMarketingHeroDc8f580c8ed14478A276939deaa7531b parentTag="Hero98a2a473B6ec472d92c07c8bacaaa3db" title="Secondary Marketing Title 2" {...props} />}
    </div>
  );
}

// id: "f3d77469-6bb8-4188-8e5b-590ebd67770d"
// title: ""
// type: :gf_preset
// key: "links"
// parent_id: "f1e1f49d-52f6-4033-b1fa-531bf89ecb79"
export function LinksF3d774696bb841888e5b590ebd67770d(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Navigation parentTag={buildClassName("LinksF3d774696bb841888e5b590ebd67770d", parentTag)} navMenuSlug="footer-nav" {...props} />
  );
}

// id: "051fc88f-abf5-4193-8f3c-b6151fc2ac7f"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "58ecdcda-f308-4bac-8433-df086fa4c3d1"
export function HeaderTop051fc88fAbf541938f3cB6151fc2ac7f(props: any) {
  return (
    <DefaultHeaderTop1b83143f537845b788f6Eb76e8910071 parentTag="HeaderTop051fc88fAbf541938f3cB6151fc2ac7f" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "89b78859-61d2-441b-a1d3-5462e5f54b75"
// title: ""
// type: :reference
// key: "label"
// parent_id: "0a3b3893-5a3a-4860-b77e-da853090e81f"
export function Label89b7885961d2441bA1d35462e5f54b75(props: any) {
  return (
    <DefaultLogo5543b37cBca44f4a8a41A526e9a50aae parentTag="Label89b7885961d2441bA1d35462e5f54b75" {...props} />
  );
}

// id: "9330c18d-9388-4ef2-9b76-72475145834a"
// title: "Default Header Reference"
// type: :reference
// key: "default-header"
// parent_id: nil
export function DefaultHeader9330c18d93884ef29b7672475145834a(props: any) {
  return (
    <ShortHeader45a335cd27de4aecA60a42c9c9c91ecc parentTag="DefaultHeader9330c18d93884ef29b7672475145834a" navMenuSlug="marketing-primary-nav" title {...props} />
  );
}

// id: "9000973c-7d1e-4ccb-9ae4-13cfd983df06"
// title: ""
// type: :gf_preset
// key: "groupflow"
// parent_id: "f1e1f49d-52f6-4033-b1fa-531bf89ecb79"
export function Groupflow9000973c7d1e4ccb9ae413cfd983df06(props: any) {
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupFlowTag parentTag={buildClassName("Groupflow9000973c7d1e4ccb9ae413cfd983df06", parentTag)} {...props} />
  );
}

// id: "d7884b43-2b15-4dc7-a47a-ca3adbd0dbc6"
// title: "Common Footer"
// type: :reference
// key: "footer"
// parent_id: nil
export function FooterD7884b432b154dc7A47aCa3adbd0dbc6(props: any) {
  return (
    <DefaultFooterF1e1f49d52f64033B1fa531bf89ecb79 parentTag="FooterD7884b432b154dc7A47aCa3adbd0dbc6" {...props} />
  );
}

// id: "dfeac988-7efa-4c13-b1b3-2a7344b910d8"
// title: ""
// type: :html
// key: "logo"
// parent_id: "f1e1f49d-52f6-4033-b1fa-531bf89ecb79"
export function LogoDfeac9887efa4c13B1b32a7344b910d8(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/orgs/mystique/logo-wide.png" alt="Mystique logo" className={`${parentTag || ""} LogoDfeac9887efa4c13B1b32a7344b910d8 logo`} />
  );
}

// id: "0e9267d9-f128-47e4-90d4-b07c487ddce1"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "f915689f-1c9b-4563-a299-a7a10facfd61"
export function Footer0e9267d9F12847e490d4B07c487ddce1(props: any) {
  return (
    <DefaultFooterF1e1f49d52f64033B1fa531bf89ecb79 parentTag="Footer0e9267d9F12847e490d4B07c487ddce1" {...props} />
  );
}

// id: "bcd7ffef-1f49-4eda-80ff-eb861e808c24"
// title: ""
// type: :reference
// key: "marketingCards"
// parent_id: "7b6b8e44-de50-4ab5-b490-e9584bb20312"
export function MarketingCardsBcd7ffef1f494eda80ffEb861e808c24(props: any) {
  return (
    <MarketingCardsDdc722c0312741ae91009665bf7472f2 parentTag="MarketingCardsBcd7ffef1f494eda80ffEb861e808c24" postSlug="home-page-posts2" {...props} />
  );
}

// id: "ff41fd24-2053-4dfc-8699-855b54943b3c"
// title: ""
// type: :text
// key: "title"
// parent_id: "5b8e24f1-295a-4b46-bd31-f72994de0543"
export const TitleFf41fd2420534dfc8699855b54943b3c = "About Us";

// id: "ddc722c0-3127-41ae-9100-9665bf7472f2"
// title: "Marketing Cards"
// type: :gf_preset
// key: "marketing-cards"
// parent_id: nil
export function MarketingCardsDdc722c0312741ae91009665bf7472f2(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <MarketingCards parentTag={buildClassName("MarketingCardsDdc722c0312741ae91009665bf7472f2", parentTag)} postSlug="home-page-posts2" {...props} />
  );
}

// id: "5ea3b780-7bc9-4379-bebc-db11566e48d4"
// title: ""
// type: :gf_preset
// key: "button"
// parent_id: "b6941852-9424-4eb0-bbd0-7b38b3a91637"
export function Button5ea3b7807bc94379BebcDb11566e48d4(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Button parentTag={buildClassName("Button5ea3b7807bc94379BebcDb11566e48d4", parentTag)} href="/join" label="Join Us" {...props} />
  );
}

// id: "049332d0-e30d-43de-9d1b-725f3e17c639"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "1b83143f-5378-45b7-88f6-eb76e8910071"
export function LinkedLogo049332d0E30d43de9d1b725f3e17c639(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogo049332d0E30d43de9d1b725f3e17c639", parentTag)} label={<DefaultLogo5543b37cBca44f4a8a41A526e9a50aae parentTag="Label32d1125b5bfc4f3d8f16Eb3d4694a049" alt="logo" />} className="linked-logo" url="/" {...props} />
  );
}

// id: "2523718b-48d6-43ad-8fe8-daea2c1f3bbf"
// title: ""
// type: :reference
// key: "header"
// parent_id: "a3ed34c7-8c5b-40dd-a632-a9174850480f"
export function Header2523718b48d643ad8fe8Daea2c1f3bbf(props: any) {
  return (
    <SecondaryMarketingHeader2cdf2519Aa4049d1B25bAa31366de347 parentTag="Header2523718b48d643ad8fe8Daea2c1f3bbf" title="Join Us" {...props} />
  );
}

// id: "ad4527d8-c724-4802-b1bb-1059277ad262"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "916443b3-010e-4cdc-ae8e-f91dc8bea5c9"
export const NavMenuSlugAd4527d8C7244802B1bb1059277ad262 = "members-primary-nav";

// id: "08c9edf5-dbd7-433e-9660-2164fd6e7c93"
// title: "Members Area"
// type: :html
// key: "members-area-default"
// parent_id: nil
export function MembersAreaDefault08c9edf5Dbd7433e96602164fd6e7c93(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MembersAreaDefault08c9edf5Dbd7433e96602164fd6e7c93 page`}>
      {props["header"] || <MembersAreaHeader58ecdcdaF3084bac8433Df086fa4c3d1 parentTag="Header916443b3010e4cdcAe8eF91dc8bea5c9" navMenuSlug="members-primary-nav" {...props} />}
      {props["footer"] || <DefaultFooterF1e1f49d52f64033B1fa531bf89ecb79 parentTag="Footer81470ecd39d947b681f576171b25fbad" {...props} />}
    </div>
  );
}

// id: "807a2b72-921b-4f5a-a0d4-daeebf3bc346"
// title: ""
// type: :reference
// key: "header"
// parent_id: "159968fc-b64d-4013-a4b4-489433dd62ac"
export function Header807a2b72921b4f5aA0d4Daeebf3bc346(props: any) {
  return (
    <SecondaryMarketingHeader2cdf2519Aa4049d1B25bAa31366de347 parentTag="Header807a2b72921b4f5aA0d4Daeebf3bc346" title="Resources" {...props} />
  );
}

// id: "a3ed34c7-8c5b-40dd-a632-a9174850480f"
// title: "Signup Pages"
// type: :reference
// key: "signup-pages"
// parent_id: nil
export function SignupPagesA3ed34c78c5b40ddA632A9174850480f(props: any) {
  return (
    <SecondaryPageB05f02a0748848138cceC46121cd1d77 parentTag="SignupPagesA3ed34c78c5b40ddA632A9174850480f" header={<SecondaryMarketingHeader2cdf2519Aa4049d1B25bAa31366de347 parentTag="Header2523718b48d643ad8fe8Daea2c1f3bbf" title="Join Us" />} {...props} />
  );
}

// id: "dc8f580c-8ed1-4478-a276-939deaa7531b"
// title: "Secondary Marketing Hero"
// type: :html
// key: "secondary-marketing-hero"
// parent_id: nil
export function SecondaryMarketingHeroDc8f580c8ed14478A276939deaa7531b(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHeroDc8f580c8ed14478A276939deaa7531b hero`}>
      <div className="title">
        {props["title"] || Title5a6ef2efDe2043229d4d01261d604e8d}
      </div>
    </div>
  );
}

// id: "2a52d892-67fb-4ba6-a419-68348b546488"
// title: ""
// type: :reference
// key: "header"
// parent_id: "b05f02a0-7488-4813-8cce-c46121cd1d77"
export function Header2a52d89267fb4ba6A41968348b546488(props: any) {
  return (
    <SecondaryMarketingHeader2cdf2519Aa4049d1B25bAa31366de347 parentTag="Header2a52d89267fb4ba6A41968348b546488" title="Secondary Page" {...props} />
  );
}

// id: "159968fc-b64d-4013-a4b4-489433dd62ac"
// title: "Resources Page"
// type: :reference
// key: "resources-page"
// parent_id: nil
export function ResourcesPage159968fcB64d4013A4b4489433dd62ac(props: any) {
  return (
    <SecondaryPageB05f02a0748848138cceC46121cd1d77 parentTag="ResourcesPage159968fcB64d4013A4b4489433dd62ac" header={<SecondaryMarketingHeader2cdf2519Aa4049d1B25bAa31366de347 parentTag="Header807a2b72921b4f5aA0d4Daeebf3bc346" title="Resources" />} {...props} />
  );
}

// id: "b05f02a0-7488-4813-8cce-c46121cd1d77"
// title: "Secondary Page"
// type: :html
// key: "secondary-page"
// parent_id: nil
export function SecondaryPageB05f02a0748848138cceC46121cd1d77(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryPageB05f02a0748848138cceC46121cd1d77 gf-page-layout`}>
      {props["header"] || <SecondaryMarketingHeader2cdf2519Aa4049d1B25bAa31366de347 parentTag="Header2a52d89267fb4ba6A41968348b546488" title="Secondary Page" {...props} />}
      <div className="text-content">
        {props.children}
      </div>
      {props["footer"] || <DefaultFooterF1e1f49d52f64033B1fa531bf89ecb79 parentTag="Footer44f54ecc31204f738c72D34f0d40d2cd" {...props} />}
    </div>
  );
}

// id: "51112a33-66c7-4e54-a1b8-05e6035cb2f0"
// title: "Message Pages"
// type: :html
// key: "message-pages"
// parent_id: nil
export function MessagePages51112a3366c74e54A1b805e6035cb2f0(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MessagePages51112a3366c74e54A1b805e6035cb2f0 gf-page-layout`}>
      {props["header"] || <MembersAreaHeader58ecdcdaF3084bac8433Df086fa4c3d1 parentTag="Header611459b1759f4b57813478467c6590f0" {...props} />}
      {props.children}
    </div>
  );
}

// id: "7b6b8e44-de50-4ab5-b490-e9584bb20312"
// title: "Marketing Cards Body"
// type: :html
// key: "marketing-body"
// parent_id: nil
export function MarketingBody7b6b8e44De504ab5B490E9584bb20312(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MarketingBody7b6b8e44De504ab5B490E9584bb20312 page-body`}>
      {props["marketingCards"] || <MarketingCardsDdc722c0312741ae91009665bf7472f2 parentTag="MarketingCardsBcd7ffef1f494eda80ffEb861e808c24" postSlug="home-page-posts2" {...props} />}
    </div>
  );
}

// id: "5543b37c-bca4-4f4a-8a41-a526e9a50aae"
// title: "Logo"
// type: :html
// key: "default-logo"
// parent_id: nil
export function DefaultLogo5543b37cBca44f4a8a41A526e9a50aae(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/orgs/mystique/logo-wide.png" className={`${parentTag || ""} DefaultLogo5543b37cBca44f4a8a41A526e9a50aae logo`} alt="logo" />
  );
}

// id: "69e1d4e0-d02e-4ccb-b45f-efc47dbee166"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "45a335cd-27de-4aec-a60a-42c9c9c91ecc"
export const BackgroundImage69e1d4e0D02e4ccbB45fEfc47dbee166 = "https://mp1md-pub.s3.amazonaws.com/orgs/sample/city-background.jpg";

// id: "916443b3-010e-4cdc-ae8e-f91dc8bea5c9"
// title: ""
// type: :reference
// key: "header"
// parent_id: "08c9edf5-dbd7-433e-9660-2164fd6e7c93"
export function Header916443b3010e4cdcAe8eF91dc8bea5c9(props: any) {
  return (
    <MembersAreaHeader58ecdcdaF3084bac8433Df086fa4c3d1 parentTag="Header916443b3010e4cdcAe8eF91dc8bea5c9" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "98a2a473-b6ec-472d-92c0-7c8bacaaa3db"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "2cdf2519-aa40-49d1-b25b-aa31366de347"
export function Hero98a2a473B6ec472d92c07c8bacaaa3db(props: any) {
  return (
    <SecondaryMarketingHeroDc8f580c8ed14478A276939deaa7531b parentTag="Hero98a2a473B6ec472d92c07c8bacaaa3db" title="Secondary Marketing Title 2" {...props} />
  );
}

// id: "516b234f-690e-4852-9b5c-5c3cca705238"
// title: ""
// type: :gf_preset
// key: "copyright"
// parent_id: "f1e1f49d-52f6-4033-b1fa-531bf89ecb79"
export function Copyright516b234f690e48529b5c5c3cca705238(props: any) {
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupCopyright parentTag={buildClassName("Copyright516b234f690e48529b5c5c3cca705238", parentTag)} {...props} />
  );
}

// id: "2db9e95d-74f1-4d15-9315-2d2836c21938"
// title: ""
// type: :reference
// key: "header"
// parent_id: "7f10eec6-5664-44e8-826b-7903f3f1dce0"
export function Header2db9e95d74f14d1593152d2836c21938(props: any) {
  return (
    <SecondaryMarketingHeader2cdf2519Aa4049d1B25bAa31366de347 parentTag="Header2db9e95d74f14d1593152d2836c21938" title="Public Events" {...props} />
  );
}

// id: "23b5ecbf-8bbc-4d11-868d-251f6a402310"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "7f10eec6-5664-44e8-826b-7903f3f1dce0"
export function Footer23b5ecbf8bbc4d11868d251f6a402310(props: any) {
  return (
    <DefaultFooterF1e1f49d52f64033B1fa531bf89ecb79 parentTag="Footer23b5ecbf8bbc4d11868d251f6a402310" {...props} />
  );
}

// id: "b4ccdc1f-75cf-4a88-9cf1-8f0204f34c6c"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "f3d77469-6bb8-4188-8e5b-590ebd67770d"
export const NavMenuSlugB4ccdc1f75cf4a889cf18f0204f34c6c = "footer-nav";

// id: "529f3631-872d-4918-859a-4779ec72c41e"
// title: "Contact"
// type: :html
// key: "contact"
// parent_id: nil
export function Contact529f3631872d4918859a4779ec72c41e(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} Contact529f3631872d4918859a4779ec72c41e gf-page-layout`}>
      {props["header"] || <SecondaryMarketingHeader2cdf2519Aa4049d1B25bAa31366de347 parentTag="Header91ebe7e4B08841d890f2000e0c69ff6d" {...props} />}
      <main className="contact-main">
        {props.children}
      </main>
      {props["footer"] || <DefaultFooterF1e1f49d52f64033B1fa531bf89ecb79 parentTag="FooterB8b3e9533d0f4f0794831f64f979be67" {...props} />}
    </div>
  );
}

// id: "7f10eec6-5664-44e8-826b-7903f3f1dce0"
// title: "Public Events Page"
// type: :html
// key: "public-events-page"
// parent_id: nil
export function PublicEventsPage7f10eec6566444e8826b7903f3f1dce0(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PublicEventsPage7f10eec6566444e8826b7903f3f1dce0 gf-page-layout`}>
      {props["header"] || <SecondaryMarketingHeader2cdf2519Aa4049d1B25bAa31366de347 parentTag="Header2db9e95d74f14d1593152d2836c21938" title="Public Events" {...props} />}
      <main className="main">
        {props.children}
      </main>
      {props["footer"] || <DefaultFooterF1e1f49d52f64033B1fa531bf89ecb79 parentTag="Footer23b5ecbf8bbc4d11868d251f6a402310" {...props} />}
    </div>
  );
}

// id: "a1306ac4-3e63-44af-9a02-cd7359306053"
// title: "HREF"
// type: :text
// key: "href"
// parent_id: nil
export const HrefA1306ac43e6344af9a02Cd7359306053 = "/";

// id: "86c92b6e-d89a-4781-b0c3-86a4d5e7a9b7"
// title: ""
// type: :gf_preset
// key: "quote"
// parent_id: "2a205d42-6183-4b87-88f3-953177c556a9"
export function Quote86c92b6eD89a4781B0c386a4d5e7a9b7(props: any) {
  const PageQuote = AppStore.presets["PageQuote"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PageQuote parentTag={buildClassName("Quote86c92b6eD89a4781B0c386a4d5e7a9b7", parentTag)} contentSlug="home-page-quote" {...props} />
  );
}

// id: "0a3b3893-5a3a-4860-b77e-da853090e81f"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "45a335cd-27de-4aec-a60a-42c9c9c91ecc"
export function LinkedLogo0a3b38935a3a4860B77eDa853090e81f(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogo0a3b38935a3a4860B77eDa853090e81f", parentTag)} label={<DefaultLogo5543b37cBca44f4a8a41A526e9a50aae parentTag="Label89b7885961d2441bA1d35462e5f54b75" alt="logo" />} {...props} />
  );
}

// id: "b8b3e953-3d0f-4f07-9483-1f64f979be67"
// title: "Footer"
// type: :reference
// key: "footer"
// parent_id: "529f3631-872d-4918-859a-4779ec72c41e"
export function FooterB8b3e9533d0f4f0794831f64f979be67(props: any) {
  return (
    <DefaultFooterF1e1f49d52f64033B1fa531bf89ecb79 parentTag="FooterB8b3e9533d0f4f0794831f64f979be67" {...props} />
  );
}

// id: "5c325ff5-df22-45b2-bd74-5a0742d404c5"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "2cdf2519-aa40-49d1-b25b-aa31366de347"
export function HeaderTop5c325ff5Df2245b2Bd745a0742d404c5(props: any) {
  return (
    <DefaultHeaderTop1b83143f537845b788f6Eb76e8910071 parentTag="HeaderTop5c325ff5Df2245b2Bd745a0742d404c5" {...props} />
  );
}

// id: "cf6e2702-4b4f-4f4c-ae17-70962679e699"
// title: "Hero"
// type: :gf_preset
// key: "hero"
// parent_id: "d937be85-ee1e-42d2-ac2b-609d1ce6a8b3"
export function HeroCf6e27024b4f4f4cAe1770962679e699(props: any) {
  const Hero = AppStore.presets["Hero"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Hero parentTag={buildClassName("HeroCf6e27024b4f4f4cAe1770962679e699", parentTag)} postSlug="home-hero" {...props} />
  );
}

// id: "73fc23be-275c-450f-9c47-599c859a05c2"
// title: ""
// type: :text
// key: "title"
// parent_id: "869ce3ef-675b-41ed-bfb6-498ab277380f"
export const Title73fc23be275c450f9c47599c859a05c2 = "Join Us";

// id: "0055fde4-eb07-4042-8163-618e29b86f78"
// title: ""
// type: :text
// key: "url"
// parent_id: "049332d0-e30d-43de-9d1b-725f3e17c639"
export const Url0055fde4Eb0740428163618e29b86f78 = "/";

// id: "f1e1f49d-52f6-4033-b1fa-531bf89ecb79"
// title: "Footer"
// type: :html
// key: "default-footer"
// parent_id: nil
export function DefaultFooterF1e1f49d52f64033B1fa531bf89ecb79(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultFooterF1e1f49d52f64033B1fa531bf89ecb79 footer`}>
      {props["social-links"] || <SocialLinks913091d1Bf154012Bc3b63ed2737ca6e />}
      {props["links"] || <Navigation parentTag={buildClassName("LinksF3d774696bb841888e5b590ebd67770d", parentTag)} navMenuSlug="footer-nav" {...props} />}
      <div className="logo-wrapper">
        {props["logo"] || <LogoDfeac9887efa4c13B1b32a7344b910d8 />}
      </div>
      <div className="copyright">
        2024 Mystique Entertainment and RDP Media Company
      </div>
      {props["groupflow"] || <GroupFlowTag parentTag={buildClassName("Groupflow9000973c7d1e4ccb9ae413cfd983df06", parentTag)} {...props} />}
    </div>
  );
}

// id: "3b2e1651-aa45-4010-b07e-e8346ec938fd"
// title: ""
// type: :text
// key: "title"
// parent_id: "2cdf2519-aa40-49d1-b25b-aa31366de347"
export const Title3b2e1651Aa454010B07eE8346ec938fd = "Contact";

// id: "5d0b7257-4c45-4ccb-9a5d-8562bfbe513a"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "ddc722c0-3127-41ae-9100-9665bf7472f2"
export const PostSlug5d0b72574c454ccb9a5d8562bfbe513a = "home-page-posts2";

// id: "25b0693d-ba7f-4c3d-88cc-474e9fa94db1"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "45a335cd-27de-4aec-a60a-42c9c9c91ecc"
export function Navigation25b0693dBa7f4c3d88cc474e9fa94db1(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("Navigation25b0693dBa7f4c3d88cc474e9fa94db1", parentTag)} navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "34db793a-1ef6-4496-9521-d111f72e9cee"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "a2c1e32d-68f9-4167-b0db-0d2319d790ce"
export const NavMenuSlug34db793a1ef644969521D111f72e9cee = "marketing-primary-nav";

// id: "d937be85-ee1e-42d2-ac2b-609d1ce6a8b3"
// title: "Home Header"
// type: :html
// key: "home-header"
// parent_id: nil
export function HomeHeaderD937be85Ee1e42d2Ac2b609d1ce6a8b3(props: any) {
  const Hero = AppStore.presets["Hero"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomeHeaderD937be85Ee1e42d2Ac2b609d1ce6a8b3 site-header`}>
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTop1b83143f537845b788f6Eb76e8910071 parentTag="HeaderTop44899e44Edae4c19941e2d488133be54" {...props} />}
      </div>
      {props["hero"] || <Hero parentTag={buildClassName("HeroCf6e27024b4f4f4cAe1770962679e699", parentTag)} postSlug="home-hero" {...props} />}
    </div>
  );
}

// id: "a2c1e32d-68f9-4167-b0db-0d2319d790ce"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "1b83143f-5378-45b7-88f6-eb76e8910071"
export function NavigationA2c1e32d68f94167B0db0d2319d790ce(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("NavigationA2c1e32d68f94167B0db0d2319d790ce", parentTag)} className="navigation" navMenuSlug="marketing-primary-nav" {...props} />
  );
}

// id: "913091d1-bf15-4012-bc3b-63ed2737ca6e"
// title: ""
// type: :html
// key: "social-links"
// parent_id: "f1e1f49d-52f6-4033-b1fa-531bf89ecb79"
export function SocialLinks913091d1Bf154012Bc3b63ed2737ca6e(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SocialLinks913091d1Bf154012Bc3b63ed2737ca6e flex flex-row justify-center gap-6 flex flex-row justify-center gap-6`}>
      <a className="hover:brightness-110" href="https://www.facebook.com/profile.php?id=61550585936211">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Facebook icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M32 0C14.356 0 0 14.356 0 32C0 49.644 14.356 64 32 64C49.644 64 64 49.644 64 32C64 14.356 49.644 0 32 0ZM32 4.92308C46.9833 4.92308 59.0769 17.0167 59.0769 32C59.0769 45.6189 49.0711 56.8173 36 58.75V39.8269H43.6442L44.8462 32.0673H36V27.8173C36 24.5927 37.0495 21.7308 40.0673 21.7308H44.9135V14.9519C44.0618 14.8387 42.2625 14.5865 38.8558 14.5865C31.7468 14.5865 27.5769 18.3428 27.5769 26.8942V32.0577H20.2692V39.8269H27.5865V58.6827C14.719 56.5766 4.92308 45.4748 4.92308 32C4.92308 17.0167 17.0167 4.92308 32 4.92308Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://www.instagram.com/mystiqueenmt/">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Instagram icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M18.5769 0C8.34616 0 0 8.34616 0 18.5769V45.4423C0 55.6538 8.34616 64 18.5769 64H45.4423C55.6538 64 64 55.6539 64 45.4231V18.5769C64 8.34616 55.6539 0 45.4231 0H18.5769ZM18.5769 4.92308H45.4231C53 4.92308 59.0769 11 59.0769 18.5769V45.4231C59.0769 53 53 59.0769 45.4423 59.0769H18.5769C11 59.0769 4.92308 53 4.92308 45.4423V18.5769C4.92308 11 11 4.92308 18.5769 4.92308ZM51.6923 9.84615C50.3269 9.84615 49.2308 10.9423 49.2308 12.3077C49.2308 13.6731 50.3269 14.7692 51.6923 14.7692C53.0577 14.7692 54.1538 13.6731 54.1538 12.3077C54.1538 10.9423 53.0577 9.84615 51.6923 9.84615ZM32 14.7692C22.5192 14.7692 14.7692 22.5192 14.7692 32C14.7692 41.4808 22.5192 49.2308 32 49.2308C41.4808 49.2308 49.2308 41.4808 49.2308 32C49.2308 22.5192 41.4808 14.7692 32 14.7692ZM32 19.6923C38.8269 19.6923 44.3077 25.1731 44.3077 32C44.3077 38.8269 38.8269 44.3077 32 44.3077C25.1731 44.3077 19.6923 38.8269 19.6923 32C19.6923 25.1731 25.1731 19.6923 32 19.6923Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://twitter.com/mystiqueenmt">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Twitter icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M8 0C3.61317 0 0 3.61317 0 8V56C0 60.3868 3.61317 64 8 64H56C60.3868 64 64 60.3868 64 56V8C64 3.61317 60.3868 0 56 0H8ZM8 5.33333H56C57.5012 5.33333 58.6667 6.49883 58.6667 8V56C58.6667 57.5012 57.5012 58.6667 56 58.6667H8C6.49883 58.6667 5.33333 57.5012 5.33333 56V8C5.33333 6.49883 6.49883 5.33333 8 5.33333ZM41.25 16C36.754 16 33.0988 19.6552 33.1042 24.1458C33.1042 24.9832 33.3958 25.5442 33.3958 26.1042C26.6545 25.8215 21.0557 22.4608 17.125 17.6875C16.2823 18.8128 16 20.2119 16 21.6146C16 24.4199 17.0931 26.6674 19.3438 28.6354C17.8504 28.4328 17.1253 28.0704 16 27.5104C16 31.4411 18.5029 34.527 22.1563 35.375C22.1563 35.375 20.7489 35.6563 19.9063 35.6563C19.2663 35.6563 18.5 35.375 18.5 35.375C19.6253 38.463 22.43 40.9896 26.0833 40.9896C23.278 42.9576 19.6221 44.3542 15.9688 44.3542H14C16.6667 46.6688 20.6635 48 26.3542 48C41.2395 48 49.3958 35.6563 49.3958 24.9896V23.8646C50.8038 22.7393 52.2027 21.3365 53.3333 19.6458C51.648 20.4938 50.2428 20.7694 48.5521 21.0521C50.2374 19.9321 51.6483 18.5252 52.2083 16.5625C50.8057 17.3998 49.1243 18.2382 47.1563 18.5208C45.7483 16.8408 43.5007 16 41.25 16Z" />
        </svg>
      </a>
    </div>
  );
}

// id: "1b0fbf0e-7cff-4493-8de1-dad88c51147c"
// title: ""
// type: :text
// key: "title"
// parent_id: "2523718b-48d6-43ad-8fe8-daea2c1f3bbf"
export const Title1b0fbf0e7cff44938de1Dad88c51147c = "Join Us";

// id: "1b83143f-5378-45b7-88f6-eb76e8910071"
// title: "Header Area Top"
// type: :html
// key: "default-header-top"
// parent_id: nil
export function DefaultHeaderTop1b83143f537845b788f6Eb76e8910071(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultHeaderTop1b83143f537845b788f6Eb76e8910071 header-top`}>
      {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogo049332d0E30d43de9d1b725f3e17c639", parentTag)} label={<DefaultLogo5543b37cBca44f4a8a41A526e9a50aae parentTag="Label32d1125b5bfc4f3d8f16Eb3d4694a049" alt="logo" />} className="linked-logo" url="/" {...props} />}
      {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("NavigationA2c1e32d68f94167B0db0d2319d790ce", parentTag)} className="navigation" navMenuSlug="marketing-primary-nav" {...props} />}
    </div>
  );
}

// id: "44f54ecc-3120-4f73-8c72-d34f0d40d2cd"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "b05f02a0-7488-4813-8cce-c46121cd1d77"
export function Footer44f54ecc31204f738c72D34f0d40d2cd(props: any) {
  return (
    <DefaultFooterF1e1f49d52f64033B1fa531bf89ecb79 parentTag="Footer44f54ecc31204f738c72D34f0d40d2cd" {...props} />
  );
}

// id: "ceb57a4e-1ed5-475a-8af4-f3515d392e42"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "e3f1e402-b1bd-4971-9702-8f74ce04e739"
export function FooterCeb57a4e1ed5475a8af4F3515d392e42(props: any) {
  return (
    <DefaultFooterF1e1f49d52f64033B1fa531bf89ecb79 parentTag="FooterCeb57a4e1ed5475a8af4F3515d392e42" {...props} />
  );
}

// id: "2e6d1593-8950-4f46-86f2-41da540dc79b"
// title: ""
// type: :text
// key: "title"
// parent_id: "807a2b72-921b-4f5a-a0d4-daeebf3bc346"
export const Title2e6d159389504f4686f241da540dc79b = "Resources";

// id: "246c8158-4f04-4c23-91ad-c65b12d3cb88"
// title: ""
// type: :html
// key: "body"
// parent_id: "0093bda0-78ec-4eee-ae16-c3367ec9c2f4"
export function Body246c81584f044c2391adC65b12d3cb88(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <main className={`${parentTag || ""} Body246c81584f044c2391adC65b12d3cb88 about-main`}>
      <div className="logo">
        <img src="https://mp1md-pub.s3.amazonaws.com/orgs/mystique/about-bg.jpeg" alt="Night club photo" />
      </div>
      <div className="text">
        <p>
          Welcome to Mystique Entertainment! We are a premier event entertainment company specializing in hosting sophisticated experiences with genuine people at top clubs and venues. As industry experts, we thrive on creating unforgettable nightlife events that keep the party going.
        </p>
        <p>
          With a team of seasoned professionals, we curate a diverse lineup of entertainment, including renowned DJs, themed parties, and much more. Our events are carefully crafted to provide an immersive and energetic atmosphere, catering to music enthusiasts, partygoers, and thrill-seekers alike.
        </p>
        <p>
          Whether you&#39;re looking for a pulsating dance floor, exclusive VIP experiences, or a themed extravaganza, we&#39;ve got you covered. Our dedication to innovation and attention to detail ensures that each event is a one-of-a-kind celebration.   We take pride in our meticulous planning, creative flair, attention to detail and making all of our
          guest feel welcome.
        </p>
        <p>
          Join us for the ultimate nightlife experience, where we fuse cutting-edge production, top-tier talent, and a vibrant community to create memories that will keep you coming back for more. Explore our upcoming events and let us elevate your nightlife escapades to new heights!
        </p>
      </div>
    </main>
  );
}

// id: "249925e0-48e9-4a43-bc0e-44d51e9c2db5"
// title: ""
// type: :text
// key: "title"
// parent_id: "9330c18d-9388-4ef2-9b76-72475145834a"
export const Title249925e048e94a43Bc0e44d51e9c2db5 = null;

// id: "3920dc0b-4f08-4812-8dc1-fa4b5a3ed290"
// title: ""
// type: :gf_preset
// key: "welcome-back"
// parent_id: "2a205d42-6183-4b87-88f3-953177c556a9"
export function WelcomeBack3920dc0b4f0848128dc1Fa4b5a3ed290(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <WelcomeBackPopUp parentTag={buildClassName("WelcomeBack3920dc0b4f0848128dc1Fa4b5a3ed290", parentTag)} {...props} />
  );
}

// id: "dfd747dc-e9eb-4941-bb92-8f1c77a24270"
// title: ""
// type: :text
// key: "label"
// parent_id: "5ea3b780-7bc9-4379-bebc-db11566e48d4"
export const LabelDfd747dcE9eb4941Bb928f1c77a24270 = "Join Us";

const Components = {
  ShortHeader45a335cd27de4aecA60a42c9c9c91ecc,
  NewComponentA705f2a6E02c4dffAea2E122c01f2d86,
  LabelB5445d48956041858f87931763f5956f,
  MembersAreaHeader58ecdcdaF3084bac8433Df086fa4c3d1,
  MemberPagesE3f1e402B1bd497197028f74ce04e739,
  Header01ac3580C9004656885cA2212a9f6cfe,
  Footer81470ecd39d947b681f576171b25fbad,
  Header869ce3ef675b41edBfb6498ab277380f,
  Title66216c21A7a54a21B150472767e61e7b,
  Header5fd60a044db04e609075Fc9cca33f184,
  PageQuoteF25e9903Ad18405790e633267f630b84,
  Header7d1a8db09bbf4aec88b638ec1ab58e05,
  Header611459b1759f4b57813478467c6590f0,
  ContentSlug216025b7Ce9f494395360a76e5532d6e,
  PostSlug2354d25aD76545deAda500824b114b9d,
  HomePage2a205d4261834b8788f3953177c556a9,
  SocialLinks05147f77Ebdb4ab8A06e4efe7dc8ab26,
  Footer9637e9a4Ea49448b93063dd7602e3eae,
  Header2524b34a42004b09B7b8Fce53567f785,
  NavMenuSlugEbb9cbbeDb8c41efB246E0412bcf458d,
  HeaderTop44899e44Edae4c19941e2d488133be54,
  Footer8546a41d4bee4418B829F988db69fdea,
  Title461bf7db681f42a281025116861747f2,
  BodyDf7db2728ced44ad95c429fa17906182,
  NavMenuSlug1346fb979fa242ebA4bc38be0ffdcfbe,
  NavMenuSlug0387fc503ecc4317Bbc85144ecf0434b,
  Header91ebe7e4B08841d890f2000e0c69ff6d,
  Alt10afbef27f6241f59dde36905cfd3fc9,
  Label32d1125b5bfc4f3d8f16Eb3d4694a049,
  SecondaryNav0bfcb43aD1c84bd18382A60ffa563106,
  ImageUrl434499a334a54489A97028974e238c53,
  Header5b8e24f1295a4b46Bd31F72994de0543,
  Class05ce8f03791e4a61A47929c86d1c16ff,
  TitleE109684244dd405cBe2bF2a095ff746d,
  Class4f303bdaFfc6431d8d2aBed18b399ff5,
  PostSlug2867bbf3340f4cc9B61eEb95cfcc47f4,
  Title89dcaf3797134bd0Bb88Db4c56889e93,
  HomePageHeroB694185294244eb0Bbd07b38b3a91637,
  AboutLevelingPageF915689f1c9b4563A299A7a10facfd61,
  Href4951fee554254a88B782959b77911b11,
  JoinUsPageFb45be27735541b9B30420b671017d44,
  Title7c4c8dbf41874d23B4346cb200b8f0c8,
  AdminPagesFe0cca34D73043e69a49F5c58547ee73,
  AboutUsPage0093bda078ec4eeeAe16C3367ec9c2f4,
  BackgroundImage629ad493E75a4b20Ba0a5aa7e07ad3cc,
  Title5a6ef2efDe2043229d4d01261d604e8d,
  SecondaryMarketingHeader2cdf2519Aa4049d1B25bAa31366de347,
  LinksF3d774696bb841888e5b590ebd67770d,
  HeaderTop051fc88fAbf541938f3cB6151fc2ac7f,
  Label89b7885961d2441bA1d35462e5f54b75,
  DefaultHeader9330c18d93884ef29b7672475145834a,
  Groupflow9000973c7d1e4ccb9ae413cfd983df06,
  FooterD7884b432b154dc7A47aCa3adbd0dbc6,
  LogoDfeac9887efa4c13B1b32a7344b910d8,
  Footer0e9267d9F12847e490d4B07c487ddce1,
  MarketingCardsBcd7ffef1f494eda80ffEb861e808c24,
  TitleFf41fd2420534dfc8699855b54943b3c,
  MarketingCardsDdc722c0312741ae91009665bf7472f2,
  Button5ea3b7807bc94379BebcDb11566e48d4,
  LinkedLogo049332d0E30d43de9d1b725f3e17c639,
  Header2523718b48d643ad8fe8Daea2c1f3bbf,
  NavMenuSlugAd4527d8C7244802B1bb1059277ad262,
  MembersAreaDefault08c9edf5Dbd7433e96602164fd6e7c93,
  Header807a2b72921b4f5aA0d4Daeebf3bc346,
  SignupPagesA3ed34c78c5b40ddA632A9174850480f,
  SecondaryMarketingHeroDc8f580c8ed14478A276939deaa7531b,
  Header2a52d89267fb4ba6A41968348b546488,
  ResourcesPage159968fcB64d4013A4b4489433dd62ac,
  SecondaryPageB05f02a0748848138cceC46121cd1d77,
  MessagePages51112a3366c74e54A1b805e6035cb2f0,
  MarketingBody7b6b8e44De504ab5B490E9584bb20312,
  DefaultLogo5543b37cBca44f4a8a41A526e9a50aae,
  BackgroundImage69e1d4e0D02e4ccbB45fEfc47dbee166,
  Header916443b3010e4cdcAe8eF91dc8bea5c9,
  Hero98a2a473B6ec472d92c07c8bacaaa3db,
  Copyright516b234f690e48529b5c5c3cca705238,
  Header2db9e95d74f14d1593152d2836c21938,
  Footer23b5ecbf8bbc4d11868d251f6a402310,
  NavMenuSlugB4ccdc1f75cf4a889cf18f0204f34c6c,
  Contact529f3631872d4918859a4779ec72c41e,
  PublicEventsPage7f10eec6566444e8826b7903f3f1dce0,
  HrefA1306ac43e6344af9a02Cd7359306053,
  Quote86c92b6eD89a4781B0c386a4d5e7a9b7,
  LinkedLogo0a3b38935a3a4860B77eDa853090e81f,
  FooterB8b3e9533d0f4f0794831f64f979be67,
  HeaderTop5c325ff5Df2245b2Bd745a0742d404c5,
  HeroCf6e27024b4f4f4cAe1770962679e699,
  Title73fc23be275c450f9c47599c859a05c2,
  Url0055fde4Eb0740428163618e29b86f78,
  DefaultFooterF1e1f49d52f64033B1fa531bf89ecb79,
  Title3b2e1651Aa454010B07eE8346ec938fd,
  PostSlug5d0b72574c454ccb9a5d8562bfbe513a,
  Navigation25b0693dBa7f4c3d88cc474e9fa94db1,
  NavMenuSlug34db793a1ef644969521D111f72e9cee,
  HomeHeaderD937be85Ee1e42d2Ac2b609d1ce6a8b3,
  NavigationA2c1e32d68f94167B0db0d2319d790ce,
  SocialLinks913091d1Bf154012Bc3b63ed2737ca6e,
  Title1b0fbf0e7cff44938de1Dad88c51147c,
  DefaultHeaderTop1b83143f537845b788f6Eb76e8910071,
  Footer44f54ecc31204f738c72D34f0d40d2cd,
  FooterCeb57a4e1ed5475a8af4F3515d392e42,
  Title2e6d159389504f4686f241da540dc79b,
  Body246c81584f044c2391adC65b12d3cb88,
  Title249925e048e94a43Bc0e44d51e9c2db5,
  WelcomeBack3920dc0b4f0848128dc1Fa4b5a3ed290,
  LabelDfd747dcE9eb4941Bb928f1c77a24270
}

export default Components;